// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-template-js": () => import("./../../../src/templates/aboutPageTemplate.js" /* webpackChunkName: "component---src-templates-about-page-template-js" */),
  "component---src-templates-basic-content-page-template-js": () => import("./../../../src/templates/basicContentPageTemplate.js" /* webpackChunkName: "component---src-templates-basic-content-page-template-js" */),
  "component---src-templates-blog-page-template-js": () => import("./../../../src/templates/blogPageTemplate.js" /* webpackChunkName: "component---src-templates-blog-page-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-business-page-template-js": () => import("./../../../src/templates/businessPageTemplate.js" /* webpackChunkName: "component---src-templates-business-page-template-js" */),
  "component---src-templates-contact-page-template-js": () => import("./../../../src/templates/contactPageTemplate.js" /* webpackChunkName: "component---src-templates-contact-page-template-js" */),
  "component---src-templates-home-page-template-js": () => import("./../../../src/templates/homePageTemplate.js" /* webpackChunkName: "component---src-templates-home-page-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/productPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-templates-solution-page-template-js": () => import("./../../../src/templates/solutionPageTemplate.js" /* webpackChunkName: "component---src-templates-solution-page-template-js" */),
  "component---src-templates-tech-page-template-js": () => import("./../../../src/templates/techPageTemplate.js" /* webpackChunkName: "component---src-templates-tech-page-template-js" */)
}

