import React, { Component } from "react";

const MenuContext = React.createContext({ isMenuOpen: false });

class MenuProvider extends Component {
  state = {
    isMenuOpen: false,
  };

  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  componentDidMount() {}

  render() {
    const { isMenuOpen } = this.state;
    return (
      <MenuContext.Provider value={{ isMenuOpen, toggleMenu: this.toggleMenu }}>
        {this.props.children}
      </MenuContext.Provider>
    );
  }
}

export default MenuContext;
export { MenuProvider };
