import React, { Component } from "react";
import { navigate } from "gatsby";
const defaultLocale = require("../constants/defaultLocale");
const localizedPath = require("../helpers/localeHelpers").localizedPath;

const LocaleContext = React.createContext({ locale: defaultLocale, locale: "/" });

class LocaleProvider extends Component {
  state = {
    locale: defaultLocale,
    path: "/",
  };

  navigateTo(locale) {
    let route = locale === defaultLocale ? `/` : `/${locale}`;
    navigate(route);
  }

  changeLocale = locale => {
    if (this.state.locale !== locale) {
      navigate(localizedPath(locale, this.state.path));
      this.setState({ locale });
    }
  };

  changePath = path => {
    this.setState({ path });
  };

  componentDidMount() {}

  render() {
    const { locale, path } = this.state;
    return (
      <LocaleContext.Provider
        value={{
          locale,
          path,
          navigateTo: this.navigateTo,
          changeLocale: this.changeLocale,
          changePath: this.changePath,
        }}>
        {this.props.children}
      </LocaleContext.Provider>
    );
  }
}

export default LocaleContext;
export { LocaleProvider };
